export var PriceRuleTier;
(function (PriceRuleTier) {
    PriceRuleTier["AllProducts"] = "AllProducts";
    PriceRuleTier["CustomAllProducts"] = "CustomAllProducts";
    PriceRuleTier["StandardProductCategory"] = "StandardProductCategory";
    PriceRuleTier["CustomProductCategory"] = "CustomProductCategory";
    PriceRuleTier["StandardBrand"] = "StandardBrand";
    PriceRuleTier["CustomBrand"] = "CustomBrand";
    PriceRuleTier["StandardProductLine"] = "StandardProductLine";
    PriceRuleTier["CustomProductLine"] = "CustomProductLine";
    PriceRuleTier["StandardPsin"] = "StandardPsin";
    PriceRuleTier["CustomPsin"] = "CustomPsin";
})(PriceRuleTier || (PriceRuleTier = {}));
