import { CalculationType } from "./CalculationType";
import { ProductSelectionType } from "./ProductSelectionType";
import { computed, reactive, ref } from "vue";
import { PriceRuleTier } from "./PriceRuleTier";
/**
 * Implementation with computed setters
 */
export class PriceRule {
    addOnAmount;
    brandId;
    endDateUtc;
    fixedPriceAmount;
    isInactive;
    marginPercentage;
    markupPercentage;
    minimumProfitAmount;
    order;
    priceAtOrAbove;
    priceAtOrBelow;
    priceCatalogId;
    priceRuleId;
    productCategoryId;
    productLineId;
    productSelectorId;
    psin;
    roundCentsUpTo;
    startDateUtc;
    tier;
    constructor(data = undefined) {
        const addOnAmount = ref(data?.addOnAmount === undefined ? null : data.addOnAmount);
        const brandId = ref(data?.brandId === undefined ? null : data.brandId);
        const endDateUtc = ref(data?.endDateUtc === undefined ? null : data.endDateUtc);
        const fixedPriceAmount = ref(data?.fixedPriceAmount === undefined ? null : data.fixedPriceAmount);
        const isInactive = ref(data?.isInactive === undefined ? false : data.isInactive);
        const marginPercentage = ref(data?.marginPercentage === undefined ? null : data.marginPercentage);
        const markupPercentage = ref(data?.markupPercentage === undefined ? null : data.markupPercentage);
        const minimumProfitAmount = ref(data?.minimumProfitAmount === undefined ? null : data.minimumProfitAmount);
        const order = ref(data?.order === undefined ? 0 : data.order);
        const priceAtOrAbove = ref(data?.priceAtOrAbove === undefined ? null : data.priceAtOrAbove);
        const priceAtOrBelow = ref(data?.priceAtOrBelow === undefined ? null : data.priceAtOrBelow);
        const priceCatalogId = ref(data?.priceCatalogId === undefined ? 0 : data.priceCatalogId);
        const priceRuleId = ref(data?.priceRuleId === undefined ? 0 : data.priceRuleId);
        const productCategoryId = ref(data?.productCategoryId === undefined ? null : data.productCategoryId);
        const productLineId = ref(data?.productLineId === undefined ? null : data.productLineId);
        const productSelectorId = ref(data?.productSelectorId === undefined ? null : data.productSelectorId);
        const psin = ref(data?.psin === undefined ? null : data.psin);
        const roundCentsUpTo = ref(data?.roundCentsUpTo === undefined ? null : data.roundCentsUpTo);
        const startDateUtc = ref(data?.startDateUtc === undefined ? null : data.startDateUtc);
        const tier = ref(data?.tier === undefined ? PriceRuleTier.AllProducts : data.tier);
        this.addOnAmount = addOnAmount;
        this.brandId = brandId;
        this.endDateUtc = endDateUtc;
        this.fixedPriceAmount = computed({
            get: () => {
                return fixedPriceAmount.value;
            },
            set: (value) => {
                fixedPriceAmount.value = value;
                // Clear invalid adjustments when fixed price is set
                if (value !== null) {
                    marginPercentage.value = null;
                    markupPercentage.value = null;
                    addOnAmount.value = null;
                    roundCentsUpTo.value = null;
                }
            }
        });
        this.isInactive = isInactive;
        this.marginPercentage = computed({
            get: () => {
                return marginPercentage.value;
            },
            set: (value) => {
                marginPercentage.value = value;
                // Clear invalid adjustments
                if (value !== null) {
                    fixedPriceAmount.value = null;
                    markupPercentage.value = null;
                }
            }
        });
        this.markupPercentage = computed({
            get: () => {
                return markupPercentage.value;
            },
            set: (value) => {
                markupPercentage.value = value;
                // Clear invalid adjustments
                if (value !== null) {
                    fixedPriceAmount.value = null;
                    marginPercentage.value = null;
                }
            }
        });
        this.minimumProfitAmount = minimumProfitAmount;
        this.order = order;
        this.priceAtOrAbove = priceAtOrAbove;
        this.priceAtOrBelow = priceAtOrBelow;
        this.priceCatalogId = priceCatalogId;
        this.priceRuleId = priceRuleId;
        this.productCategoryId = productCategoryId;
        this.productLineId = productLineId;
        this.productSelectorId = productSelectorId;
        this.psin = psin;
        this.roundCentsUpTo = roundCentsUpTo;
        this.startDateUtc = startDateUtc;
        this.tier = computed({
            get: () => {
                return tier.value;
            },
            set: (value) => {
                tier.value = value;
                switch (value) {
                    case PriceRuleTier.AllProducts:
                        productCategoryId.value = null;
                        brandId.value = null;
                        productLineId.value = null;
                        psin.value = null;
                        productSelectorId.value = null;
                        break;
                    case PriceRuleTier.StandardProductCategory:
                        brandId.value = null;
                        productLineId.value = null;
                        psin.value = null;
                        productSelectorId.value = null;
                        break;
                    case PriceRuleTier.StandardBrand:
                        productLineId.value = null;
                        psin.value = null;
                        productSelectorId.value = null;
                        break;
                    case PriceRuleTier.StandardProductLine:
                        productCategoryId.value = null;
                        brandId.value = null;
                        psin.value = null;
                        productSelectorId.value = null;
                        break;
                    case PriceRuleTier.StandardPsin:
                        productCategoryId.value = null;
                        brandId.value = null;
                        productLineId.value = null;
                        productSelectorId.value = null;
                        break;
                    case PriceRuleTier.CustomAllProducts:
                    case PriceRuleTier.CustomBrand:
                    case PriceRuleTier.CustomProductCategory:
                    case PriceRuleTier.CustomProductLine:
                    case PriceRuleTier.CustomPsin:
                        productCategoryId.value = null;
                        brandId.value = null;
                        psin.value = null;
                        productLineId.value = null;
                }
            }
        });
    }
    static create(data = undefined) {
        return reactive(new PriceRule(data));
    }
    getProductSelectionType() {
        switch (this.tier) {
            case PriceRuleTier.StandardProductCategory:
                return ProductSelectionType.Category;
            case PriceRuleTier.StandardBrand:
                return ProductSelectionType.Brand;
            case PriceRuleTier.StandardProductLine:
                return ProductSelectionType.ProductLine;
            case PriceRuleTier.StandardPsin:
                return ProductSelectionType.Psin;
            case PriceRuleTier.CustomAllProducts:
            case PriceRuleTier.CustomBrand:
            case PriceRuleTier.CustomProductCategory:
            case PriceRuleTier.CustomProductLine:
            case PriceRuleTier.CustomPsin:
                return ProductSelectionType.Selector;
            case PriceRuleTier.AllProducts:
            default:
                return ProductSelectionType.AllProducts;
        }
    }
    getCalculationType() {
        if (this.marginPercentage !== null) {
            return CalculationType.MarginPercent;
        }
        else if (this.markupPercentage !== null) {
            return CalculationType.MarkupPercent;
        }
        else if (this.fixedPriceAmount !== null) {
            return CalculationType.FixedAmount;
        }
        return null;
    }
}
