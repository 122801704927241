import { PriceRuleTier } from "@/models";
import { nameOfFactory, RestApi_1_0 } from "@points/common";
export async function getPriceRuleForPsin(psin, priceCatalogId) {
    const p = nameOfFactory();
    return (await RestApi_1_0.getPendingPriceCatalogPriceRuleODataV1(priceCatalogId, {
        $filter: `${p("tier")} eq '${PriceRuleTier.StandardPsin}' and ` +
            `${p("psin")} eq ${psin} and ` +
            `(${p("startDateUtc")} eq null or ${p("startDateUtc")} lt cast(${new Date().toISOString()},Edm.DateTimeOffset)) and ` +
            `${p("endDateUtc")} eq null`,
        $orderby: `${p("startDateUtc")} desc, ${p("order")} asc`,
        $top: 1
    })).items[0];
}
