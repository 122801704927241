// import { configureErrorRouter, configureOidcRouter, configureVersionRouter } from "@points/common";
import { globals } from "../utilities/globals";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: "news",
        component: () => import("@/components/inventory/news.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/products",
        name: "products",
        component: () => import("@/components/products/select-product.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/catalogs",
        name: "catalogs",
        component: () => import("@/components/inventory/catalogs.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/selectors",
        name: "selectors",
        component: () => import("@/components/inventory/ProductSelectors.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/acquisition",
        name: "acquisition-cost",
        component: () => import("@/components/inventory/AcquisitionCost.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/transfer-route",
        name: "transfer-route",
        component: () => import("@/components/location/transfer-routes/transfer-routes.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/routes",
        name: "routes",
        component: () => import("@/components/location/routes/route-manager.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/location-manager",
        name: "location-manager",
        component: () => import("@/components/location/locations/location-manager.vue"),
        meta: { requiresAuth: true }
    }
];
const options = {
    mode: "history",
    base: globals.BASE_URL,
    routes,
    scrollBehavior(_to, _from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        else {
            return { x: 0, y: 0 };
        }
    }
};
const router = new VueRouter(options);
// configureOidcRouter(router);
// configureErrorRouter(router);
// configureVersionRouter(router);
export default router;
