/* eslint-disable @typescript-eslint/no-unsafe-member-access -- Manual null and type checks used*/
const missing = [];
if (!process.env.VUE_APP_SUPPORTPHONE)
    missing.push("VUE_APP_SUPPORTPHONE");
if (!process.env.VUE_APP_AUTHORITY)
    missing.push("VUE_APP_AUTHORITY");
if (!process.env.VUE_APP_CENTRAL)
    missing.push("VUE_APP_CENTRAL");
if (!process.env.VUE_APP_PATH)
    missing.push("VUE_APP_PATH");
if (!process.env.BASE_URL)
    missing.push("BASE_URL");
if (!process.env.NODE_ENV)
    missing.push("NODE_ENV");
if (!process.env.VUE_APP_OIDC_CLIENT_NAME)
    missing.push("VUE_APP_OIDC_CLIENT_NAME");
if (missing.length)
    throw new Error(`Missing required environment settings: ${missing.join(", ")}`);
export const globals = Object.freeze({
    VUE_APP_APPLICATIONINSIGHTS: process.env.VUE_APP_APPLICATIONINSIGHTS ?? "",
    VUE_APP_SUPPORTPHONE: process.env.VUE_APP_SUPPORTPHONE ?? "",
    VUE_APP_BUILD_ID: process.env.VUE_APP_BUILD_ID ?? "",
    VUE_APP_AUTHORITY: process.env.VUE_APP_AUTHORITY ?? "",
    VUE_APP_CENTRAL: process.env.VUE_APP_CENTRAL ?? "",
    BASE_URL: process.env.BASE_URL ?? "",
    VUE_APP_PATH: process.env.VUE_APP_PATH ?? "",
    VUE_APP_NAME: process.env.VUE_APP_NAME ?? "VUE_APP_NAME_NOT_FOUND",
    VUE_APP_ENVIRONMENT: process.env.VUE_APP_ENVIRONMENT ?? "VUE_APP_ENVIRONMENT_NOT_FOUND",
    VUE_APP_VERSION: process.env.VUE_APP_VERSION ?? "VUE_APP_VERSION_NOT_FOUND",
    NODE_ENV: process.env.NODE_ENV ?? "",
    BUILD_ID: process.env.BUILD_ID ?? "local",
    VUE_APP_DEVTOOLS: process.env.VUE_APP_DEVTOOLS ?? "",
    VUE_APP_OIDC_CLIENT_NAME: process.env.VUE_APP_OIDC_CLIENT_NAME ?? "VUE_APP_OIDC_CLIENT_NAME_NOT_FOUND"
});
