import pinia from "@/store";
import PointSCommon from "@points/common";
import Vue from "vue";
import moment from "vue-moment";
import App from "./App.vue";
import "./plugins/axios";
import { globals } from "./utilities/globals";
import vuetify from "./plugins/vuetify";
import router from "./router";
// This needs to be imported last
import "./styles/app.scss";
Vue.config.productionTip = false;
Vue.config.devtools = !!globals.VUE_APP_DEVTOOLS;
// Vue.use(VueCompositionApi);
Vue.use(PointSCommon, {
    ...globals,
    pinia,
    router
    // axiosConfiguration: setupAlltracAxios
});
Vue.use(moment);
// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-argument -- obsolete untyped library
Vue.use(require("vue-shortkey"));
const { hostname, href, protocol } = location;
if (hostname !== "localhost") {
    if (hostname.includes("ndtirepartner.com"))
        location.replace("https://www.tfdistro.com/b2blogin");
    const isApex = hostname.split(".")[1] === "com";
    const isHttp = protocol === "http:";
    if (isApex && isHttp)
        location.replace(href.replace("http://", "https://www."));
    else if (isApex)
        location.replace(href.replace("https://", "https://www."));
    else if (isHttp)
        location.replace(href.replace("http://", "https://"));
}
new Vue({
    router,
    pinia,
    vuetify,
    el: "#app",
    render: (h) => h(App)
});
