import { CleanApi_1_0, RestApi_1_0, useAction, useActions } from "@points/common";
import { computed, ref } from "vue";
import { defineStore } from "pinia";
import Vue from "vue";
export const PRODUCT_CATEGORY_FILTER_PATH = "Brand/ProductLine/ProductCategoryName";
export const BRAND_FILTER_PATH = "Brand/Brand";
export const PRODUCT_LINE_FILTER_PATH = "Brand/ProductLine/ProductLine";
export const PSIN_FILTER_PATH = "Psin";
export const usePricingFilterStore = defineStore("pricing-filter", function setup() {
    /**
    * Search index field definitions used by pricing to define product selector filters.
    * Keyed by ProductCategoryName. Includes all fields in crossCategoryPricingFilterFields.
    */
    const pricingFilterFieldsByProductCategoryName = ref({});
    /**
    * Search index field definitions used by pricing to define product selector filters.
    * Available on all product categories.
    */
    const crossCategoryPricingFilterFields = ref(undefined);
    /**
     * ProductCategory names and ids
     */
    const productCategories = ref(undefined);
    /**
     * @returns The productFieldId of the search index field which contains ProductCategoryName
     */
    const productCategoryFieldId = computed(() => {
        return crossCategoryPricingFilterFields
            .value
            ?.find((x) => x.filterPath === PRODUCT_CATEGORY_FILTER_PATH)
            ?.productFieldId;
    });
    /**
     * Load product categories on-demand into state.productCategories.
     */
    const loadProductCategories = useAction("throttle", async () => {
        if (productCategories.value)
            return;
        productCategories.value ??= (await RestApi_1_0.getProductCategoryLookupV1()).items;
    });
    /**
     * Loads search index field definitions for a specific category
     * into state.pricingFilterFieldsByProductCategoryName[productCategoryName]
     */
    const loadPricingFilterFields = useAction("throttle", async (productCategoryName) => {
        if (pricingFilterFieldsByProductCategoryName.value[productCategoryName])
            return;
        const facets = (await CleanApi_1_0.getInventoryPricingFilterFieldsByProductCategoryName(productCategoryName)).items;
        if (!pricingFilterFieldsByProductCategoryName.value[productCategoryName])
            Vue.set(pricingFilterFieldsByProductCategoryName.value, productCategoryName, facets.sort((a, b) => {
                const diff = (a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER);
                if (diff === 0)
                    return (a.displayName || "").localeCompare(b.displayName);
                return diff;
            }));
    }, (name) => name);
    /**
     * Loads search index field definitions which exist for every category
     * into state.crossCategoryPricingFilterFields
     */
    const loadCrossCategoryPricingFilterFields = useAction("throttle", async () => {
        if (!crossCategoryPricingFilterFields.value)
            crossCategoryPricingFilterFields.value ??= (await CleanApi_1_0.getInventoryPricingFilterFields()).items;
    });
    /**
     * Group ProductSelector filters into a search filter structure to use for
     * display and mapping to OData requests.
     * @param filters ProductSelector filters
     * @returns filters mapped into ValueSearchFilter groupings by filterPath
     */
    const getSearchFilters = useAction("parallel", async (filters) => {
        // Load cross category fields to search for category field id
        await loadCrossCategoryPricingFilterFields.execute();
        // Get the field id for determining if this selector has a product category
        const fieldId = productCategoryFieldId.value;
        if (!fieldId)
            throw new Error("Product category pricing filter field was not found on server.");
        // Get displayName filter for category
        const productCategoryName = filters
            ?.find((filter) => filter.productFieldId === fieldId)
            ?.jsonValueFilter;
        // If selector has a category load field-definitions for that category
        if (productCategoryName)
            await loadPricingFilterFields.execute(productCategoryName);
        // Get fields which apply to the selector
        const fields = productCategoryName
            ? pricingFilterFieldsByProductCategoryName.value[productCategoryName]
            : crossCategoryPricingFilterFields.value;
        // Group filters on product selector into search filters
        const searchFilters = filters.reduce((searchFiltersByPath, filter) => {
            // Find the field related to this filter
            const field = fields
                ?.find((filterField) => filterField.productFieldId === filter.productFieldId);
            if (!field)
                throw Error(`Could not find search field with productFieldId: ${filter.productFieldId} in productCategory '${productCategoryName}'`);
            // Get info used to create search filter from selector filter
            const { filterPath, type } = field;
            const fieldType = type;
            if (!searchFiltersByPath[filterPath])
                // If group has not been created, insert it
                searchFiltersByPath[filterPath] = {
                    filterPath,
                    fieldType,
                    isExcludeFilter: filter.isExcludeFilter,
                    values: [],
                    facetType: "Value"
                };
            // Add value to group
            searchFiltersByPath[filterPath].values?.push(filter.jsonValueFilter);
            return searchFiltersByPath;
        }, {});
        return Object.values(searchFilters);
    });
    /**
     * Get a human-readable description for a ProductSelector
     * @param filters
     * @returns
     */
    async function getSelectorDescription(productSelector) {
        const filters = productSelector.filters;
        // Group product selector filters into search filters by path
        const searchFilters = await getSearchFilters.execute(filters);
        // Get the product category filter
        const productCategoryName = searchFilters
            .find((x) => x.filterPath === PRODUCT_CATEGORY_FILTER_PATH)
            ?.values?.[0]
            ?.toString();
        if (!productCategoryName) {
            console.error(`ProductSelector with id ${productSelector.productSelectorId} does not contain a ProductCategory filter.`);
            return ["Definition is not available"];
        }
        const fields = pricingFilterFieldsByProductCategoryName.value[productCategoryName];
        return searchFilters.reduce((description, filter) => {
            const field = fields
                .find((field) => filter.filterPath === field.filterPath);
            if (!field)
                console.error(`Could not find product search field with filter path: '${filter.filterPath}' in productCategory '${productCategoryName}' when generating selector description for ProductSelector '${productSelector.productSelectorId}'.`);
            else {
                const comparison = `${filter.isExcludeFilter ? "is not" : "is"}${(filter.values?.length ?? 0) > 1 ? " one of:" : ""}`;
                // String.fromCharCode(160) = non-breaking whitespace for more consistent display
                description.push(`${field.displayName} ${comparison} ${filter.values?.map((value) => `'${value?.replaceAll(" ", String.fromCharCode(160))}'`).join(", ")}`);
            }
            return description;
        }, []);
    }
    return {
        ...useActions({
            loadProductCategories,
            loadPricingFilterFields,
            loadCrossCategoryPricingFilterFields,
            getSearchFilters
        }),
        pricingFilterFieldsByProductCategoryName,
        crossCategoryPricingFilterFields,
        productCategories,
        productCategoryFieldId,
        getSelectorDescription
    };
});
