import * as api from "@/api";
import { compare } from "fast-json-patch";
import { CleanApi_1_0, RestApi_1_0, setTimeoutInterval, useAction, useActions } from "@points/common";
import { computed, ref } from "vue";
import { defineStore } from "pinia";
export const useCatalogStore = defineStore("catalog", function setup() {
    // const catalogs = ref<Page<RestApi_1_0.PendingPriceCatalogReadModel>>({ items: [] });
    const catalogs = ref({
        items: [], count: 0, nextPageLink: null
    });
    const selectedCatalog = ref({});
    /**
     * Store the last status loaded from the server
     */
    const pendingPricesAreRecalculatingStatus = ref(false);
    /**
     * Track a polling timeout for settling calculation status
     */
    const recalculatingTimeout = ref(null);
    const recalculatePendingPricesIsExecuting = computed(() => {
        return pendingPricesAreRecalculatingStatus.value || recalculatePendingPrices.isExecuting.value;
    });
    /**
     * Recalculate the pending prices for this company
     */
    const recalculatePendingPrices = useAction("throttle", async () => {
        await RestApi_1_0.rebuildPendingPricesV1();
        // Fake isExecuting check by polling a job status endpoint
        if (!recalculatingTimeout.value) {
            pendingPricesAreRecalculatingStatus.value = true;
            recalculatingTimeout.value = setTimeoutInterval(() => loadRecalculatingStatus.execute(), 5000);
        }
    });
    /**
     * Poll the recalculating status until it settles
     */
    const loadRecalculatingStatus = useAction("throttle", async () => {
        // const recalculatingCatalogs = await api.getRecalculatingStatus(options);
        const recalculatingCatalogs = await CleanApi_1_0.recalculateUnpublishedPricesJob();
        pendingPricesAreRecalculatingStatus.value = recalculatingCatalogs === "Active" || recalculatingCatalogs === "Queued";
        if (!pendingPricesAreRecalculatingStatus.value) {
            recalculatingTimeout.value?.();
            recalculatingTimeout.value = null;
            const catalogId = selectedCatalog.value.priceCatalogId;
            if (catalogId) {
                const status = await api.getCatalogStatus(catalogId);
                if (selectedCatalog.value.priceCatalogId === catalogId)
                    selectedCatalog.value.hasPendingChanges = status.hasPendingChanges;
                selectedCatalog.value.lastPendingPriceRefreshUtc = status.lastPendingPriceRefreshUtc;
            }
        }
    });
    const addSubscriber = useAction("parallel", async (newSubscriber) => {
        const addedSubscriber = await RestApi_1_0.postPendingPriceCatalogSubscriberByPriceCatalogIdV1(selectedCatalog.value.priceCatalogId || 0, newSubscriber, { getResponse: true });
        selectedCatalog.value.subscribers ??= [];
        selectedCatalog.value.subscribers.push(addedSubscriber);
    });
    const createCatalog = useAction("parallel", async (newCatalog) => {
        const addedCatalog = await RestApi_1_0.postPendingPriceCatalogV1(newCatalog);
        catalogs.value.items.unshift(addedCatalog);
        selectedCatalog.value = addedCatalog;
    });
    const deleteSubscriber = useAction("parallel", async (id) => {
        await RestApi_1_0.deletePendingPriceCatalogSubscriberByIdV1(id, selectedCatalog.value.priceCatalogId || 0);
        selectedCatalog.value.subscribers = [...selectedCatalog.value.subscribers?.filter((subscriber) => subscriber.id !== id) || []];
    });
    const loadCatalogs = useAction("parallel", async (oDataQueryOptions) => {
        // TODO(matthew): get catalog subscribers to map subscriber names
        catalogs.value = await RestApi_1_0.getPendingPriceCatalogV1(oDataQueryOptions);
    });
    const updateCatalog = useAction("parallel", async (oldCatalog, newCatalog) => {
        if (oldCatalog.priceCatalogId) {
            const patchDocument = compare(oldCatalog, newCatalog);
            const catalog = await RestApi_1_0.patchPendingPriceCatalogByIdV1(oldCatalog.priceCatalogId, patchDocument, { getResponse: true });
            // NOTE(matthew): calling a patch endpoint with getResponse: true always returns data
            selectedCatalog.value = catalog;
        }
    });
    return {
        /**
         * the catalogs
         */
        catalogs,
        selectedCatalog,
        recalculatePendingPrices: () => recalculatePendingPrices.execute(),
        recalculatePendingPricesIsExecuting,
        ...useActions({
            addSubscriber,
            createCatalog,
            deleteSubscriber,
            loadCatalogs,
            updateCatalog
        })
    };
});
