export var ProductSelectionType;
(function (ProductSelectionType) {
    ProductSelectionType["AllProducts"] = "AllProducts";
    ProductSelectionType["Brand"] = "Brand";
    ProductSelectionType["Category"] = "Category";
    ProductSelectionType["ProductLine"] = "ProductLine";
    ProductSelectionType["Psin"] = "Psin";
    ProductSelectionType["Selector"] = "Selector";
})(ProductSelectionType || (ProductSelectionType = {}));
export function getFriendlyProductSelectionName(productSelection) {
    switch (productSelection) {
        case ProductSelectionType.AllProducts: return "All Products";
        case ProductSelectionType.Brand: return "Brand";
        case ProductSelectionType.Category: return "Product Category";
        case ProductSelectionType.ProductLine: return "Product Line";
        case ProductSelectionType.Psin: return "PSIN";
        case ProductSelectionType.Selector: return "Custom Selector";
    }
}
