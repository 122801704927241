import { nameOfFactory, RestApi_1_0 } from "@points/common";
import axios from "axios";
export async function getCatalogStatus(catalogId) {
    const p = nameOfFactory();
    const response = await RestApi_1_0.getPendingPriceCatalogV1({
        $filter: `${p("priceCatalogId")} eq ${catalogId}`,
        // TODO: use this select if odata is fixed in .net 6
        // $select: `${p("lastPendingPriceRefreshUtc")},${p("hasPendingChanges")}`,
        $top: 1
    });
    if (!response.items.length)
        throw new Error("Catalog not found");
    return response.items[0];
}
export async function downloadPriceCSV(priceType, priceCatalogId) {
    const response = await axios.get(`v1/${priceType}/${priceCatalogId}/ProductPrice/csv`);
    return response.data;
}
